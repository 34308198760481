.footerTitle {
    font-weight: 600;
    font-size: 16px;
}
.footerUnderTitle {
    color: #948E8E;
    font-weight: 500;
    line-height: 2;
    font-size: 15px;
    text-decoration: none;
}
.footerUnderTitle a {
    color: #948E8E;
    font-weight: 500;
    line-height: 2;
    font-size: 14px;
    text-decoration: none;
}
