.cloudsDiv {
    background: url('../images/About/clouds.svg') no-repeat;
    background-size: contain;
    background-position-x: center;
}

.iluDiv {}

.iluDiv img {
    width: 65vw
}

.greyTextAbout {
    color: #ACADB1;
    line-height: 27px;
    font-weight: 500;
}

.cloudsDiv2 {
    background: url('../images/About/clouds2.svg') no-repeat;
    background-size: contain;
    background-position-x: center;
}

.faqClosed {
    height: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
    visibility: hidden;
}

.faqOpen {
    opacity: 1;
    transition: all .3s ease-in-out;
    visibility: visible;
    height: 160px;
}

.faqTitle {
    font-size: 20px;
    font-weight: 700;
}

.faqSecondTitle {
    font-weight: 700;
    line-height: 120%;
    font-size: 16px;
}

.faqThirdTitle {
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;
}

.faqBlueBg {
    background: #112035;
    border-radius: 8px;
    color: #fff;
}
.marginLeftFaq {
    margin-left: -85px;
}
.marginLeftFaq img {
    width: 220px;
    margin-bottom: -45px;
}
@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
    .faqOpen {
        height: 160px;
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
    .marginLeftFaq {
        margin-left: -110px;
    }
}

@media (max-width: 575.98px) {
    .faqOpen {
        height: 220px;
    }
    .marginLeftFaq {
        margin-left: -0px;
        margin-top: -7rem;
        text-align: center;
    }
    .iluDiv img {
        width: 100%
    }
    .greyTextAbout { 
        font-size: 14px;
    }
    .aboutImgs {
        width: 180px;
    }
    .faqThirdTitle {
        font-size: 12px;
    }
    .faqTitle {
        font-size: 16px;
    }
    .faqSecondTitle {
        font-size: 14px;
    }
}