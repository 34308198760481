@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

body, p, span, table, div, a {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.homeFirstSection {
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    border: none;
    background: #2F60DC;
    border-radius: 8px;
    padding: 0.75rem 1.75rem;
}

.homeSecondTitle {
    font-weight: 500;
    font-size: 17px;
    color: #112035;
}

body {
    padding-top: 8rem;
}

.homeFirstTitle {
    font-weight: 700;
    font-size: 80px;
}



.blueBgDiv {
    background: url('../images/blueMountains.svg') no-repeat;
    height: 1710px;
    margin-top: -200px;
    margin-bottom: -1300px;
}

.sliderCards {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 3rem 1rem 3rem;
    cursor: pointer;
    color: #000 !important;
    text-decoration: none !important; 
}

.sliderButton {
    border: 2px solid #2F60DC;
    border-radius: 8px;
    background-color: #2F60DC;
    color: #FFFFFF;
    font-weight: 600;
    padding: 0.5rem 3rem;
    font-size: 18px;
    transition: all 0.2s ease-in-out;

}

.sliderButton:hover {
    border: 2px solid #2F60DC;
    background-color: #E7EDFF;
    color: #2F60DC;
    transition: all 0.2s ease-in-out;
    transform: scale(1.01);
}

.darkBlueBg {
    background: url('../images/darkBackgroundDesktop.svg') no-repeat;
    background-size: cover;
    /* height: 820px; */
}


.lastSectionBtn {
    background: #2F60DC;
    border-radius: 8px;
    font-weight: 600;
    color: #FFFFFF;
    border: none;
    padding: 0.65rem 0rem;
    width: 300px;
}


.infoDiv {
    background: #E6EDFF;
    border-radius: 8px;
    text-align: left !important;
    font-weight: 400;
    font-size: 14px;
}

.sliderCards .pb-2 {
    font-size: 18px;
}

.imgMarginTop {
    margin-top: -24rem;
}

.fs5Custom {
    font-size: 20px !important;
}

.sliderCards img {
    width: 110px;
}

.impressumText a {
    word-break: break-all;
}
@media (min-width: 1600px) {
    .blueBgDiv {
        margin-bottom: -1250px;
    }
}

@media (min-width: 1899px) {
    .blueBgDiv {
        height: 1900px;
        margin-bottom: -1350px;
    }
}

@media (min-width: 2100px) {
    .blueBgDiv {
        height: 2000px;
        margin-left: -150px;
        margin-top: -450px;
        background-size: cover;
        margin-bottom: -1150px;

    }
}

@media (min-width: 2200px) {
    .blueBgDiv {
        height: 2200px;
        margin-top: -500px;
        margin-bottom: -1250px;
        background-size: cover;
    }
}

@media (min-width: 2450px) {
    .blueBgDiv {
        height: 2310px;
        margin-top: -500px;
        margin-bottom: -1300px;
    }
}

@media (min-width: 1399.98px) {
    .darkBlueBg {
        background: url('../images/darkBackgroundDesktop.svg') no-repeat;
        background-size: cover;
        /* height: 1150px; */

    }

}
@media (max-width: 1540.98px) {
    .homeFirstTitle {
        font-size: 60px;
    }
    
    .homeSecondTitle {
        font-weight: 500;
        font-size: 16px;
        color: #112035;
    }
}
@media (max-width: 1399.98px) {
    .imgMarginTop {
        margin-top: -25rem;
    }

    .blueBgDiv {
        margin-bottom: -1350px;
        margin-top: -200px;
    }

    body {
        padding-top: 7rem;
    }

    .homeFirstTitle {
        font-size: 60px;
    }
}

@media (max-width: 1199.98px) {
    .darkBlueBg {
        height: auto;
    }

    .imgMarginTop {
        margin-top: -22rem;
    }

    
    .blueBgDiv {
        background: url('../../assets/images/Group2400.svg') no-repeat;
        background-size: cover;
        margin-top: -50px;
        margin-bottom: -1200px;
    }
}

@media (max-width: 991.98px) {
    
    .blueBgDiv { 
        margin-bottom: -1300px;
        margin-top: 100px;

    }
}

@media (max-width: 767.98px) {
    .darkBlueBg {
        background-size: cover;
    }

    .blueBgDiv {
        background: url('../../assets/images/Group2400.svg') no-repeat;
        background-size: cover;
        margin-top: -100px;
        margin-bottom: -1450px;
    }

    .telephoneSvgDiv {
        margin-bottom: -9rem;
        text-align: center;
        margin-top: 10rem;

    }

    .homeFirstTitle {
        font-size: 50px;
    }

    .telephoneSvgDiv svg {
        width: 284px;
        height: 272px;
    }

    .lastSectionBtn {
        width: 50%;
    }

    .fs5Custom {
        font-size: 18px !important;
    }

    .sliderButton {
        font-size: 16px;
    }
}

@media (max-width: 575.98px) {
    .homeFirstSection {
        font-size: 14px;
    }
    .blueBgDiv {
        background: url('../../assets/images/Group2400.svg') no-repeat;
        background-position-x: center;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: -1550px;
        background-size: contain;

    }

    body {
        padding-top: 7rem;
    }

    .homeFirstTitle {
        font-size: 30px;
    }

    .sliderCards span {
        font-size: 14px;
        display: none;
    }

    .responsiveTextMobile .fs-3 {
        font-size: 21px !important;
    }

    .responsiveTextMobile .fs-5 {
        font-size: 18px !important;
    }

    .sliderCards .pb-2 span {
        font-size: 16px;
        display: block;
    }

    .sliderCards.text-start span {
        display: block;
    }

    .sliderCards .pt-3 span {
        display: block;
    }

    .cloudPhoto {
        width: 315px;
    }

    .darkBlueBg {
        background: url('../images/darkBackgroundMobile.svg') no-repeat;
        background-size: cover;
        height: 1750px;
    }

    .sliderCards img {
        width: 80px;
    }

    .telephoneSvgDiv {
        margin-bottom: -6rem;
        text-align: center;
        margin-top: 0rem;
    }

    .lastSectionBtn {
        width: 100%;
    }
    .homeSecondTitle { 
        font-size: 14px;
    }
}