.angebotBlueBg {
    position: relative;
    background: #3A5274;
    border-radius: 10px 0px 0px 10px;
    height: 500px;
    color: #fff;
}

.angebotBlueBg2 {
    background: #3A5274;
    border-radius: 0px 10px 10px 0px;
    height: 500px;
    color: #fff;
    border-left: 1px solid #fff;
}

.handDiv {
    position: absolute;
    left: 400px;
    top: -60px;
    z-index: 9;
}

.handDiv img {
    width: 200px;
}

.girl {
    width: 500px;
    z-index: 9;
    position: absolute;
}

.angebotInputs {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    border-radius: 0px !important;
    z-index: 10;
    position: relative;
    color: #fff !important;
}
.angebotPaddingBottom {
    padding-bottom: 15rem;
}
@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
    .girl {
        width: 350px;
        top: 140px;
    }

    .handDiv {
        left: 200px;
        top: -45px;
    }

    .handDiv img {
        width: 150px;
    }
}

@media (max-width: 991.98px) {
    .angebotPaddingBottom {
        padding-bottom: 7rem;
    }
}

@media (max-width: 767.98px) {
    .handDiv {
        display: none;
    }

    .girl {
        display: none;
    }

    .angebotBlueBg {
        height: 100%;
    }

    .angebotBlueBg2 {
        height: 100%;
    }
    .angebotPaddingBottom {
        padding-bottom: 0rem;
    }
}

@media (max-width: 575.98px) {
    .angebotBlueBg {
        border-radius: 10px 10px 0px 0px;
    }
    .angebotBlueBg2 {
        border-left: none;
        border-radius: 0px 0px 10px 10px;
    }
    .hideMobile {
        display: none;
    }
}

@media (max-width: 345.98px) {}