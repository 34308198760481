.cloudsDivB {
    background: url('../images/business/clouds.svg') no-repeat;
    background-size: contain;
    background-position-x: 50%;
}

.businessSecondTitle {
    font-weight: 700;
    line-height: 130.02%;
    letter-spacing: 0.02em;
    color: #112035;

}

.businessBlueSpan {
    font-weight: 600;
    font-size: 17px;
    line-height: 138.02%;
    color: #2F60DC;
}
.businessImgs {
    max-width: 500px;
}
.businessLtBlueBg {
    background: url('../images//business/businessBg.svg') no-repeat;
    background-size: cover;
    background-position-x: 50%;
    height: 1250px;
}

@media (max-width: 1399.98px) {
    .businessLtBlueBg {
        height: auto;
    }
}

@media (max-width: 1199.98px) {
    
}

@media (max-width: 991.98px) {
   
}

@media (max-width: 767.98px) {
    
}

@media (max-width: 575.98px) {
   
}