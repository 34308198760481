.cardBg {
    background: url('../images/dlf/card.svg') no-repeat;
    background-size: cover;
    width: 270px;
    height: 122px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.underCard {
    background: #F6F8FE;
    border-radius: 0px 0px 20px 20px;
    margin-top: -15px;
}

.faqOpenDl {
    opacity: 1;
    transition: all .3s ease-in-out;
    visibility: visible;
    height: 70px;
}

.dlfimages {
    width: 220px;
}

.blueBgBDlf {
    background: url('../images/dlf/dlfBg.svg') no-repeat;
    background-size: cover;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1540.98px) {
    .dlfimages {
        width: 180px;
    }
}

@media (max-width: 1399.98px) {
    .cardBg {
        background-size: cover;
    }
}

@media (max-width: 1199.98px) {
    .cardBg {
        background-size: cover;
    }

    .faqOpenDl {
        height: 65px;
    }
}

@media (max-width: 991.98px) {
    .blueBgBDlf {
        height: 1800px;
    }
}

@media (max-width: 767.98px) {
    .cardBg {
        width: 100%;
        background-size: cover;
    }

    .dlfimages {
        width: 150px;
    }
}

@media (max-width: 665.98px) {
    .cardBg {
        background-size: contain;
    }

    .underCard {
        margin-top: -22px;
    }
}

@media (max-width: 575.98px) {
    .cardBg {
        background-size: cover;
    }

    .underCard {
        margin-top: -15px;
    }
    .underCard span {
        font-size: 14px;
    }
    .faqOpenDl {
        height: 100px;
    }

    .blueBgBDlf {
        height: 2460px;
        background-position-x: 25%;
    }
}

@media (max-width: 320.98px) {
    .cardBg {
        background-size: contain;
    }

    .underCard {
        margin-top: -20px;
    }

    .faqOpenDl {
        height: 120px;
    }
}