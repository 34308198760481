@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&family=Work+Sans:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Raleway';
}

span {
    font-family: 'Raleway';
}

p {
    font-family: 'Raleway';
}

a {
    font-family: 'Raleway';
}
.blueBorderDiv {
    border: 1px solid #2F60DC;
    border-radius: 8px;
    color: #2F60DC;
    font-weight: 500;
    cursor: pointer;
}
.navBg {
    background-color: #fff;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

}
.navLinks {
    font-weight: 600;
    text-decoration: none !important;
    color: #000;
}

.navLinks:hover svg path {
    color: #2f60dc;
    stroke: #2f60dc !important;
}
.navTabs {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0rem;
    transition: all 0.4s ease-in-out;
    left: 1000px;
    animation: none;
    background-color: #E6EDFF;
    height: 100vh;
    width: 100%;
}

.navTabsOpen {
    left: 0px;
    transition: all 0.4s ease-in-out;
}
.fullLogo {
    width: 190px;
}
.navbarDropdowns {
    padding: 0.75rem 0rem;
    text-align: left !important;
    left: 1rem;
    width: max-content;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 1px 1px;
    border-radius: 8px;
    word-break: keep-all !important;
}
.navbarDropdownsWrap {
    padding-top: 25px;
    text-align: left;
    position: absolute;
    left: 0rem;
    top: 0px;
    width: max-content;

}

@media (max-width: 991.98px) {
    .fullLogo {
        width: 180px;
    }
    
}
@media (max-width: 767.98px) { 
    .fullLogo {
        width: 170px;
    }
    .navLinks span {
        font-size: 16px;
    }
    
}
@media (max-width: 575.98px) { 
    .fullLogo {
        width: 150px;
    }
}