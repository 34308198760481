.privateBlueBg {
    background: url('../images/Private/privateBg.svg') no-repeat !important;
    background-size: cover;
    height: 1417px;
}
@media (max-width: 1540.98px) {
    .businessBlueSpan {
        font-size: 16px;
    }
}
@media (max-width: 1399.98px) {
    
}

@media (max-width: 1199.98px) {
    .privateBlueBg {
        display: flex;
        justify-content: center;
        align-items: center;
    } 
}

@media (max-width: 991.98px) {
   
}

@media (max-width: 767.98px) {
    
}

@media (max-width: 575.98px) {
   
}